<template>
  <div class="vipMain">
    <div class="backHome">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('home')"></span>
      {{currentLang.title}}
    </div>
    <div class="vipTitleBar">
      <span :class="['vip1', vipActive ? 'vipActive' : '']" @click="upgrade('jinka')">{{currentLang.goldMember}}</span>
      <span :class="['vip2', vipActive ? '' : 'vipActive']"
        @click="upgrade('yinka')">{{currentLang.silverMember}}</span>
    </div>
    <div :class="['vipPic', vipActive ? '' : 'vipPicActive']" :style="{ backgroundImage: 'url(' + vippic + ')' }">
      {{ vipText }}
    </div>
    <div class="vipMonthBox" v-for="(item,i) in vipMonthJson" :class="[nowActive == i ?'touchActive':'']" :key="item.id"
      @click="selectGrade(item,i)">
      <span class="vd1">{{ item.month }}{{currentLang.month}}</span>
      <span class="vd2">${{ item.money }}/{{ item.bili }}{{currentLang.month}}</span>
      <span class="vd3">{{currentLang.discount}}{{ item.safe }}%</span>
      <span class="vd4">${{ item.number }}</span>
    </div>

    <div class="vipTitleDetail">{{currentLang.memberRights}}</div>
    <div class="vipDetail">
      <ul>

        <li v-for="vd in vipDetail" >
          {{ vd.text }}
        </li>

    <!--    <li v-for="vd in vip2Detail" >
          {{ vd.text }}
        </li> -->

      </ul>
    </div>
    <div class="payToVip">
      <span class="moneyText">{{currentLang.totalAmount}}：<i>${{ money }}</i></span>
      <span class="goPay" @click="goPay">{{currentLang.upgrade}}</span>
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "",
    props: {},
    data() {
      return {
        nowActive: "",
        vipActive: true,
        selectActive: true,
        //  require("@/assets/img/shuaige.png"),
        vippic: require("@/assets/img/vip3.png"),
        vipText: "金卡会员",
        vipGradeID: "", //用钱数+id 到后台判断是否符合升级标准，如果符合，则升级并且切换对应的有效期
        vipGradeDays: "31", //本次购买的天数。按照天数存  默认是金卡的第一个档次的天数
        vipMonthJson: [],
        vip1MonthJson: [{
            month: "1",
            money: "188",
            bili: "1", //月
            safe: "0",
            number: "188",
            days: "31",
          },
          {
            month: "3",
            money: "129.33",
            bili: "1",
            safe: "31",
            number: "388",
            days: "93",
          },
          {
            month: "6",
            money: "100.33",
            bili: "1",
            safe: "47",
            number: "588",
            days: "180",
          },
          {
            month: "12",
            money: "89.33",
            bili: "1",
            safe: "65",
            number: "788",
            days: "365",
          },
        ],
        vip2MonthJson: [{
            month: "1",
            money: "18",
            bili: "1",
            safe: "0",
            number: "18",
            days: "31",
          },
          {
            month: "3",
            money: "50",
            bili: "1",
            safe: "31",
            number: "50",
            days: "93",
          },
        ],
        vip2Detail: [
          // {
          //   id: "1",
          //   text: "允许个人资料上墙帖",
          // },
          {
            id: "1",
            text: "搜索用戶",
          },
          {
            id: "1",
            text: "查看個人資料",
          },
          // {
          //   id: "1",
          //   text: "聊天發圖片免費",
          // },
          // {
          //   id: "1",
          //   text: "允許在動態下面評論",
          // },
          {
            id: "1",
            text: "無免費條數限制",
          },
          // {
          //   id: "1",
          //   text: "允许發動態",
          // },
          // {
          //   id: "1",
          //   text: "上传照片",
          // },
          {
            id: "1",
            text: "查看图片",
          },
          // {
          //   id: "1",
          //   text: "评论图片",
          // },
          // {
          //   id: "1",
          //   text: "添加视频",
          // },
          // {
          //   id: "1",
          //   text: "查看视频",
          // },
          // {
          //   id: "1",
          //   text: "评论视频",
          // },
          {
            id: "1",
            text: "启用邮件对话框",
          },
          {
            id: "1",
            text: "继续邮件对话框",
          },
          {
            id: "1",
            text: "阅读聊天消息",
          },
          {
            id: "1",
            text: "免費發送聊天消息",
          },
          {
            id: "1",
            text: "啟用聊天輸入框",
          },
          {
            id: "1",
            text: "阅读邮件",
          },
          {
            id: "1",
            text: "继续聊天对话框",
          },
          // {
          //   id: "1",
          //   text: "免費使用翻譯",
          // },
        ],
        vipDetail: [
          // {
          //   id: "1",
          //   text: "允许个人资料上墙帖",
          // },
          {
            id: "1",
            text: "搜索用戶",
          },
          {
            id: "1",
            text: "查看個人資料",
          },
          {
            id: "1",
            text: "聊天發圖片免費",
          },
          // {
          //   id: "1",
          //   text: "允許在動態下面評論",
          // },
          {
            id: "1",
            text: "無免費條數限制",
          },
          {
            id: "1",
            text: "允许發動態",
          },
          {
            id: "1",
            text: "上传照片",
          },
          {
            id: "1",
            text: "查看图片",
          },
          // {
          //   id: "1",
          //   text: "评论图片",
          // },
          // {
          //   id: "1",
          //   text: "添加视频",
          // },
          // {
          //   id: "1",
          //   text: "查看视频",
          // },
          // {
          //   id: "1",
          //   text: "评论视频",
          // },
          {
            id: "1",
            text: "启用邮件对话框",
          },
          {
            id: "1",
            text: "继续邮件对话框",
          },
          {
            id: "1",
            text: "阅读聊天消息",
          },
          {
            id: "1",
            text: "免費發送聊天消息",
          },
          {
            id: "1",
            text: "啟用聊天輸入框",
          },
          {
            id: "1",
            text: "阅读邮件",
          },
          {
            id: "1",
            text: "继续聊天对话框",
          },
          // {
          //   id: "1",
          //   text: "免費使用翻譯",
          // },
        ],
        money: 188.0,
        languageLib: {
          lang_1: {
            title: 'Upgrade Membership',
            goldMember: 'GoldMember',
            silverMember: 'silverMember',
            month: 'Month',
            discount: 'Discount',
            memberRights: 'Member\'s Right',
            totalAmount: 'Total Amount',
            upgrade: 'Upgrade'
          },
          lang_2: {
            title: '升级会员',
            goldMember: '金卡会员',
            silverMember: '银卡会员',
            month: '月',
            discount: '优惠',
            memberRights: '会员特权',
            totalAmount: '总价',
            upgrade: '升级会员'
          },
          lang_3: {
            title: '升級會員',
            goldMember: '金卡會員',
            silverMember: '銀卡會員',
            month: '月',
            discount: '優惠',
            memberRights: '會員特權',
            totalAmount: '總價',
            upgrade: '升級會員'
          }
        },
        currentLang: {

        }
      };
    },
    components: {},
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2' ];
    },
    mounted() {
      this.vipMonthJson = this.vip1MonthJson //加载金卡的配置
      this.vipGradeID = "7979"; //默认金卡的id
      this.getUserInfo();
    },
    methods: {
      back(where) {
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        } else {
          this.$router.back()
        }
  
      },
      async getUserInfo() {
        //获取用户信息，如果没登录，则跳转到登录
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
		  token: Cookies.get("token"),
          sitenum: '18777777777'
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //that.userName = result.name;
          that.amount = result.kje; //会员的账户余额
          that.userGrade = result.fklx;

        } else {
          this.$router.push("login");
        }
      },
      upgrade(type) {
        if (type == "jinka") {
          (this.vipActive = true), (this.vippic = require("@/assets/img/vip3.png"));
          this.vipText = "金卡会员";
          this.vipMonthJson = this.vip1MonthJson; //加载金卡的配置
          this.vipGradeID = "7979";

          this.money = 188; //套餐价，一口价
          this.vipGradeDays = 31
        } else {
          (this.vipActive = false), (this.vippic = require("@/assets/img/basis.png"));
          this.vipText = "银卡会员";
          this.vipMonthJson = this.vip2MonthJson; //加载银卡的配置
          this.vipGradeID = "7978";

          this.money = 18; //套餐价，一口价
          this.vipGradeDays = 31
        }
      },
      selectGrade(item, i) {
        this.nowActive = i
        //用户选择购买的套餐
        this.money = item.number; //套餐价，一口价
        this.vipGradeDays = item.days

      },
      async goPay() {

        //原来是金卡的，禁止升级银卡
        console.log(this.vipText + "=========>" + this.userGrade)
        if (this.userGrade == "金卡会员" && this.vipText == "银卡会员") {
          Toast("金卡会员不能购买银卡会员的服务");
          return;
        }

        var that = this;
        var uri = "";
        uri = this.$global.domain + "/WXpay/notify_url_collage.aspx";
        var param = {
          act: "shengjiklxpay",
          user: Cookies.get("kh"),
          je: this.money, //本次要支付的钱，这里默认就用余额
          days: this.vipGradeDays, //本次延长多少天有效期
          nklxid: this.vipGradeID, //要升级的id，支付后，会员等级发生变化
          paytype: '账户余额',
          sitenum: "18777777777",
          sblx: 'web', //xcx则调用小程序支付 gzh则调用公众号统一支付
          token: Cookies.get("token")
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push("main");
            //延迟时间：3秒
          }, 1000)
        } else {
          Toast(result.msg);
        }
      }
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  .vipTitleBar {
    height: 42px;
    width: 348px;
    position: relative;
    line-height: 42px;
    text-align: left;
    // border: 1px red solid;
    margin: 0 auto;

    .vip1 {
      height: 42px;
      width: 80px;
      text-align: center;
      font-weight: 600;
      display: inline-block;
    }

    .vip2 {
      display: inline-block;
      height: 42px;
      width: 80px;
      text-align: center;
      font-weight: 600;
      margin-left: 25px;
    }

    .vipActive {
      color: #d23d5b;
    }
  }

  .vipPic {
    height: 146px;
    width: 272px;
    position: relative;
    line-height: 52px;
    text-align: left;
    margin: 10px auto;
    border-radius: 20px;
    background-color: #fe893c;
    text-indent: 20px;
    color: #8b572a;
    font-weight: 800;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .vipPicActive {
    color: #dedede !important;
  }

  .vipMonthBox {
    height: 32px;
    width: 348px;
    position: relative;
    line-height: 52px;
    text-align: left;
    margin: 22px auto 0 auto;
    border-radius: 6px;
    border: 1px #d6d6d6 solid;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .vd1 {
      left: 30px;
      top: 14px;
      font-size: 18px;
      font-weight: 600;
      color: #222;
      width: 120px;
      height: 20px;
      line-height: 20px;
    }

    .vd2 {
      left: 16px;
      top: 36px;
      font-size: 12px;
      color: #222;
      width: 72px;
      height: 20px;
      line-height: 20px;
    }

    .vd3 {
      left: 90px;
      top: 36px;
      font-size: 12px;
      font-weight: 600;
      color: #d23d5b;
      width: 90px;
      height: 20px;
      line-height: 20px;
    }

    .vd4 {
      left: 232px;
      top: 18px;
      font-size: 30px;
      font-weight: 600;
      color: #d23d5b;
      width: 90px;
      height: 32px;
      line-height: 32px;
    }
  }

  .vipTitleDetail {
    height: 34px;
    width: 375px;
    position: relative;
    line-height: 34px;
    text-align: left;
    margin: 0 auto;
    margin-top: 22px;
    background-color: #f4f9fa;
    text-align: left;
    font-size: 16px;
    text-indent: 15px;
    color: #989898;
  }

  .vipDetail {
    ul {
      li {
        width: 340px;
        height: 20px;
        font-size: 14px;
        color: #222;
        text-align: left;
        margin: 7px auto;
      }

      li:first-child {
        margin-top: 28px;
      }

      li:before {
        content: " ";
        width: 16px;
        height: 16px;
        display: inline-block;
        background-position: 0px 2px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-image: url("../../assets/img/new1.png");
      }
    }
  }

  .payToVip {
    height: 62px;
    width: 100%;
    position: fixed;
    background-color: white;
    box-shadow: 2px 3px 19px #bfb8b8;
    display: inline-block;
    bottom: 0px;
    z-index: 998;

    .moneyText {
      height: 22px;
      line-height: 22px;
      width: 178px;
      text-align: left;

      color: #222;
      font-size: 14px;
      display: inline-block;
      left: 18px;
      position: absolute;
      top: 18px;

      // border: 1px red dashed;
      i {
        font-style: inherit;
        color: #d23d5b;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .goPay {
      height: 62px;
      line-height: 62px;
      width: 150px;
      background-color: #fe4c57;
      text-align: center;
      color: white;
      font-size: 18px;
      display: inline-block;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }



  }

  .touchActive {
    color: #fff;
    background: #ff5d3e;
    border: 1px solid #ff5d3e;

    span {
      color: #ffc96b !important;

    }
  }
</style>
